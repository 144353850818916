import React, { useCallback } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import dynamic from "next/dynamic";
import { WithTranslation, withTranslation } from "next-i18next";

import { palette } from "src/themes/palette";
import {
  Body1RegularA,
  H4Bold,
  H1Bold,
} from "src/components/Typography/Typography";
import { translate } from "src/locales";

type Props = WithTranslation;

const Container = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FourZeroFourLogo = styled(H1Bold)`
  color: ${palette.mediumgrey.main};
  margin-bottom: 20px;
`;

const Title = styled(H4Bold)`
  color: ${palette.darkgrey.dark};
  margin-bottom: 16px;
`;

const Description = styled(Body1RegularA)`
  color: ${palette.darkgrey.main};
`;

const Email = styled(Body1RegularA)`
  color: ${palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`;

const AccountLayout = dynamic(
  () => import("src/components/Layout/AccountLayout"),
  { ssr: false },
);

const FourZeroFour = (props: Props) => {
  const { t } = props;
  const emailTo = useCallback(() => {
    window.location.href = "mailto:support@kodax.com";
  }, []);
  return (
    <AccountLayout>
      <Container>
        <FourZeroFourLogo>404</FourZeroFourLogo>
        <Title>{translate(["pages", "404", "title"], t)}</Title>
        <Description>
          {translate(["pages", "404", "description"], t)}
        </Description>
        <Email onClick={emailTo}>support@kodax.com</Email>
      </Container>
    </AccountLayout>
  );
};

export default withTranslation()(FourZeroFour);
